// extracted by mini-css-extract-plugin
export var anchor = "styles-module--anchor--wA4rr";
export var block = "styles-module--block--FVKNy";
export var captionText = "styles-module--captionText--G7OzV";
export var columns = "styles-module--columns--icCsw";
export var content = "styles-module--content--XLmMM";
export var left = "styles-module--left--30A8+";
export var picture = "styles-module--picture---zoMu";
export var right = "styles-module--right--+4EjD";
export var title = "styles-module--title--EBsNs";
export var titleText = "styles-module--titleText--9OI3O";